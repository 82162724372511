import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.trim.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var comment = function comment() {
  return import("./comment/comment");
};

var myFooter = function myFooter() {
  return import("./common/myFooter");
};

export default {
  components: {
    comment: comment,
    myFooter: myFooter
  },
  data: function data() {
    return {
      show: false,
      messageContent: "",
      // background: {"background": "url(" + this.$store.state.webInfo.backgroundImage + ") center center / cover no-repeat"},
      barrageList: []
    };
  },
  created: function created() {
    this.getTreeHole();
  },
  methods: {
    getTreeHole: function getTreeHole() {
      var _this = this;

      this.$http.get(this.$constant.baseURL + "/webInfo/listTreeHole").then(function (res) {
        if (!_this.$common.isEmpty(res.data)) {
          res.data.forEach(function (m) {
            _this.barrageList.push({
              id: m.id,
              avatar: m.avatar,
              msg: m.message,
              time: Math.floor(Math.random() * 10 + 5)
            });
          });
        }
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    submitMessage: function submitMessage() {
      var _this2 = this;

      if (this.messageContent.trim() === "") {
        this.$message({
          message: "你还没写呢~",
          type: "warning"
        });
        return;
      }

      var treeHole = {
        message: this.messageContent.trim()
      };

      if (!this.$common.isEmpty(this.$store.state.currentUser) && !this.$common.isEmpty(this.$store.state.currentUser.avatar)) {
        treeHole.avatar = this.$store.state.currentUser.avatar;
      }

      this.$http.post(this.$constant.baseURL + "/webInfo/saveTreeHole", treeHole).then(function (res) {
        if (!_this2.$common.isEmpty(res.data)) {
          _this2.barrageList.push({
            id: res.data.id,
            avatar: res.data.avatar,
            msg: res.data.message,
            time: Math.floor(Math.random() * 10 + 5)
          });
        }
      }).catch(function (error) {
        _this2.$message({
          message: error.message,
          type: "error"
        });
      });
      this.messageContent = "";
      this.show = false;
    }
  }
};